import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
  Dispatch,
  FC
} from "react";

import DotButton from "./DotButton";
import memoize from "utils/memoize";
import { isMobileOrTablet } from "hooks/useDeviceOrientation";
import PlayIcon from "icons/PlayIcon";
import RHImage from "@RHCommerceDev/component-rh-image";
import { ProductImagePresetKeys } from "utils/getImageUrlWithPreset";
import Arrows from "./Arrows";
import { COUNTER_ONE, COUNTER_TWO } from "utils/constants";
import { useDebounce } from "hooks/useDebounce";

interface ImageCarouselProps {
  slides: ProductAlternateImage[];
  imageContainerStyle?: React.CSSProperties;
  imageStyle?: any;
  presetImage?: any;
  imageFlip?: boolean;
  isClicked?: boolean;
  selectedSwatchUrl?: {
    isColorizable?: boolean;
    selectedSwatchImageUrl?: string;
  };
  pdpImageProps?: any;
  setVideo?: React.Dispatch<React.SetStateAction<string>>;
  setIsClicked?: Dispatch<React.SetStateAction<Boolean>>;
  setActiveIndex?: Dispatch<React.SetStateAction<number | undefined>>;
  setIsZoomViewerDialogOpen?: Dispatch<React.SetStateAction<Boolean>>;
}
const ImageCarousel: FC<ImageCarouselProps> = ({
  slides = [],
  imageStyle,
  presetImage,
  imageFlip = false,
  isClicked,
  selectedSwatchUrl,
  pdpImageProps,
  setVideo,
  setIsClicked,
  setActiveIndex
}) => {
  const [currentIndex, setCurrentIndex] = useState(1);
  const [transitionEnabled, setTransitionEnabled] = useState(true);
  const carouselRef = useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLImageElement>(null);
  const slideCount = slides.length;
  const [touchStartX, setTouchStartX] = useState<number | null>(null);
  const [touchEndX, setTouchEndX] = useState<number | null>(null);
  const [previousIndex, setPreviousIndex] = useState(0); // Track the previous index
  const [reverseCount, setReverseCount] = useState(0);
  const [reverseIndex, setReverseIndex] = useState(0);
  const [showCarousel, setShowCarousel] = useState(false);
  const [stopFlip, setStopFLip] = useState(false);
  const { debounce } = useDebounce();

  useEffect(() => {
    // is used for colorization
    if (isClicked) {
      setTransitionEnabled(false);
      setCurrentIndex(1);
      setIsClicked?.(false);
    }
  }, [isClicked]);

  useEffect(() => {
    if (currentIndex && setActiveIndex) {
      setActiveIndex?.(currentIndex - 1);
    }
  }, [currentIndex, setActiveIndex]);

  useEffect(() => {
    if (
      selectedSwatchUrl?.selectedSwatchImageUrl !== undefined &&
      selectedSwatchUrl?.selectedSwatchImageUrl !== " "
    ) {
      setCurrentIndex(1);
    }
  }, [selectedSwatchUrl?.selectedSwatchImageUrl]);

  useEffect(() => {
    const element = ref?.current;

    const handleOnPointerOver = async () => {
      if (slides?.length) {
        setShowCarousel(true);
      }
    };
    element?.addEventListener("pointerover", handleOnPointerOver);

    return () => {
      element?.removeEventListener("pointerover", handleOnPointerOver);
    };
  }, [currentIndex, ref, slides?.length]);

  const goToNextSlide = useCallback(
    e => {
      if (currentIndex !== slides?.length) {
        e?.preventDefault();
        !showCarousel && setShowCarousel(true);
        setCurrentIndex(prevIndex => {
          setPreviousIndex(prevIndex);
          return prevIndex + 1;
        });
      }
    },
    [showCarousel, currentIndex]
  );

  const goToPrevSlide = useCallback(
    e => {
      if (currentIndex !== 1) {
        e?.preventDefault();
        !showCarousel && setShowCarousel(true);
        setCurrentIndex(prevIndex => {
          setPreviousIndex(prevIndex);
          return prevIndex - 1;
        });
      }
    },
    [currentIndex, showCarousel]
  );

  // const startAutoplay = useCallback(() => {
  //   setIsAutoplay(true);
  // }, []);

  // const stopAutoplay = useCallback(() => {
  //   setIsAutoplay(false);
  // }, []);

  // useEffect(() => {
  //   if (!isAutoplay) return;
  //   const intervalId = setInterval(e => {
  //     goToNextSlide(e);
  //   }, 1000); // Adjust time interval as needed
  //   return () => clearInterval(intervalId);
  // }, [isAutoplay, goToNextSlide]);

  // useEffect(() => {
  //   if (isAutoplay && !isMobileOrTablet) {
  //     if (carouselRef.current) {
  //       carouselRef.current.addEventListener("mouseenter", startAutoplay);
  //       carouselRef.current.addEventListener("mouseleave", stopAutoplay);
  //     }
  //     return () => {
  //       if (carouselRef.current && !isMobileOrTablet) {
  //         carouselRef.current.removeEventListener("mouseenter", startAutoplay);
  //         carouselRef.current.removeEventListener("mouseleave", stopAutoplay);
  //       }
  //     };
  //   }
  // }, [ isAutoplay, stopAutoplay]);

  const scrollTo = useCallback((index: number, e?: any) => {
    if (e) {
      e.preventDefault();
    }
    setCurrentIndex(prev => {
      setPreviousIndex(prev);
      return index;
    });
  }, []);

  const handleTouchStart = (e: React.TouchEvent) => {
    setShowCarousel(true);
    setTouchStartX(e.touches[0].clientX);
    setTouchEndX(null);
  };
  const handleTouchMove = (e: React.TouchEvent) => {
    setTouchEndX(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (!touchStartX || !touchEndX) return;
    const diff = touchStartX - touchEndX;
    if (diff > 50) {
      goToNextSlide({ preventDefault: () => {} });
    } else if (diff < -50) {
      goToPrevSlide({ preventDefault: () => {} });
    }
    setTouchStartX(null);
    setTouchEndX(null);
  };
  useEffect(() => {
    if (currentIndex === slideCount + 1) {
      setTransitionEnabled(false);
      setCurrentIndex(prev => {
        setPreviousIndex(prev);
        return 1;
      });
    } else if (currentIndex === 0) {
      setTransitionEnabled(false);
      setCurrentIndex(prev => {
        setPreviousIndex(prev);
        return slideCount;
      });
    } else {
      setTransitionEnabled(true);
    }
  }, [currentIndex, slideCount]);

  useEffect(() => {
    if (previousIndex > currentIndex - 1) {
      setReverseCount(reverseCount + 1);
    } else {
      setReverseCount(0);
      setReverseIndex(0);
    }
  }, [currentIndex]);

  useEffect(() => {
    if (reverseCount == 1) {
      setReverseIndex(previousIndex);
    }
  }, [reverseCount]);

  useEffect(() => {
    if (reverseCount == 1) {
      setReverseIndex(previousIndex);
    }
  }, [reverseCount]);

  if (slides.length === 0) return null;

  const getVisibleDots = () => {
    const totalDots = slides.length;
    if (totalDots <= 5) {
      return slides.map((_, index) => index);
    }
    return Array.from({ length: slides.length }, (_, i) => i);
  };

  const alternateImagesList = useMemo(() => {
    let newImageLists = [
      ...(slides?.length && showCarousel ? slides : [slides?.[0]])
    ];

    if (
      newImageLists?.length &&
      selectedSwatchUrl?.isColorizable &&
      selectedSwatchUrl?.selectedSwatchImageUrl
    ) {
      newImageLists.splice(0, 1, {
        imageUrl: selectedSwatchUrl?.selectedSwatchImageUrl
      });
    }
    return newImageLists;
  }, [
    selectedSwatchUrl?.isColorizable,
    selectedSwatchUrl?.selectedSwatchImageUrl,
    showCarousel,
    slides
  ]);

  const handleFlip = useCallback(
    (e, idx) => {
      if (
        !isMobileOrTablet &&
        imageFlip &&
        idx === 0 &&
        alternateImagesList?.length > 1 &&
        !stopFlip
      ) {
        setCurrentIndex(() => {
          setPreviousIndex(COUNTER_ONE);
          return COUNTER_TWO;
        });
      }
    },
    [alternateImagesList?.length, imageFlip, stopFlip]
  );

  const handleRemoveFlip = useCallback(() => {
    if (!isMobileOrTablet && alternateImagesList?.length > 1) {
      debounce(500, () => {
        setStopFLip(true);
        setCurrentIndex(() => {
          setPreviousIndex(0);
          return 1;
        });
      });
    }
  }, [alternateImagesList?.length, debounce]);

  const stopClickPropogation = e => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <>
      <div
        className="flex items-end relative  group/item group  flex-col justify-center"
        style={{ touchAction: "pan-x", height: "auto" }}
        ref={ref}
      >
        <div
          className={`embla overflow-hidden w-full z-10 relative group/item group block  max-h-[${
            imageStyle?.maxHeight
          }px]  ${isMobileOrTablet ? "inline-table" : ""}`}
          ref={carouselRef}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          style={{
            height: imageStyle?.maxHeight ?? "auto"
          }}
        >
          <div
            className="embla__container flex h-full items-center"
            style={{
              transform: `translateX(-${(currentIndex - 1) * 100}%)`,
              transition: transitionEnabled
                ? "transform 0.5s ease-in-out"
                : "none"
            }}
            // onMouseOut={handleRemoveFlip}
          >
            {alternateImagesList?.map((item, idx) => (
              <div
                key={idx}
                className={`embla__slide min-w-0 h-[300px] flex-[0_0_100%] z-20 justify-around`}
                style={{
                  height: item ? imageStyle?.maxHeight || "auto" : 250,
                  cursor: item.video ? "pointer" : "default"
                }}
                // onMouseOver={e => handleFlip(e, idx)}
                onClick={() => {
                  if (item.video) {
                    setVideo?.(
                      item.video.includes(".com")
                        ? item.video // scene 7 url
                        : item.video.split("&")[0] // youtube id
                    );
                  }
                }}
              >
                <RHImage
                  key={`productImage-${idx}`}
                  data-testid={"mobile-pdp-image"}
                  loadingBehavior={idx === 0 ? "eager" : "lazy"}
                  objectFit={
                    pdpImageProps?.objectFit ||
                    (pdpImageProps?.isRHRImageStyling ? "contain" : "")
                  }
                  // setIsHeroImageUrlFailed={setIsHeroImageUrlFailed}
                  failedImageUrl={
                    pdpImageProps?.isHeroImageUrlFailed
                      ? (item || [])[0]?.imageUrl
                      : ""
                  }
                  src={
                    presetImage ? presetImage(item?.imageUrl) : item?.imageUrl
                  }
                  imageWrapperClassName="flex justify-center h-[376px]"
                  alt={item?.caption || item?.imageUrl}
                  preset={
                    `${
                      pdpImageProps?.imagePresetOverride?.length
                        ? pdpImageProps?.imagePresetOverride
                        : "pdp-hero"
                    }-${pdpImageProps?.mediaString}` as ProductImagePresetKeys
                  }
                  onClick={() => {
                    if (item.video) {
                      setVideo?.(
                        item.video.includes(".com")
                          ? item.video // scene 7 url
                          : item.video.split("&")[0] // youtube id
                      );
                    }
                  }}
                >
                  {item.video !== "" && Boolean(item?.video) && (
                    <PlayIcon
                      className={
                        "absolute left-0 right-0 top-0 bottom-0  m-auto text-white text-[60px] pointer-events-none cursor-pointer z-10  "
                      }
                    />
                  )}
                </RHImage>
              </div>
            ))}
          </div>

          {/* Action handlers */}
        </div>
        <div
          className={`embla__controls flex justify-center align-center items-center w-full mt-[13px] mb-[8.5px]`}
        >
          {/* Dots */}
          <div
            className={`embla__dots cursor-default flex ${
              slides.length <= 4 ? "justify-center" : "justify-start"
            } items-center overflow-hidden max-w-[57px] h-[8px]`}
            onClick={stopClickPropogation}
          >
            <div
              className={`flex transition-transform duration-300  gap-2 h-[8px]`}
              style={{
                transform: `translateX(-${
                  previousIndex - 1 <= currentIndex - 1
                    ? currentIndex - 1 >= 4 &&
                      currentIndex - 1 < slides.length - 1
                      ? (currentIndex - 4) * 13
                      : currentIndex - 1 >= slides.length - 1
                      ? (currentIndex - 5) * 13
                      : 0
                    : currentIndex - 1 <= slides.length - 1 &&
                      currentIndex - 1 != 0
                    ? reverseCount >= 3
                      ? (currentIndex - 2) * 13
                      : currentIndex - 1 >= slides.length - 3
                      ? (reverseIndex - 5) * 13
                      : reverseIndex >= 3 && slides.length > 5
                      ? (reverseIndex - 5) * 13
                      : 0
                    : currentIndex - 1 == 0
                    ? 0
                    : (currentIndex - 5) * 13
                }px)`
              }}
            >
              {slides.length > 1 &&
                getVisibleDots().map(index => (
                  <DotButton
                    key={index}
                    selected={index === currentIndex - 1}
                    selectedIndex={currentIndex - 1}
                    // onClick={e => scrollTo(index + 1,e)}
                    index={index}
                    totalDots={slides.length}
                    reverseIndex={reverseIndex - 1}
                    isReverse={previousIndex > currentIndex}
                  />
                ))}
            </div>
          </div>
        </div>
        {/* Arrows */}
        <div className="md:block hidden absolute self-center h-full w-full z-0">
          <Arrows
            scrollPrev={goToPrevSlide}
            scrollNext={goToNextSlide}
            index={currentIndex}
            slides={slideCount}
          />
        </div>
      </div>
    </>
  );
};
export default memoize(ImageCarousel);

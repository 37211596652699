import React, { useContext, useEffect, useMemo, useState } from "react";
import yn from "yn";
import { memoryStorage } from "utils/analytics/storage";
import useLocale from "hooks-use-locale/useLocale";
import { useAppId } from "hooks-use-app-id";
import { useRhUserAtomValue } from "hooks/atoms";
import { useEnv } from "hooks/useEnv";
import { useQuery } from "@apollo/client";
import { queryProduct } from "graphql-client-product";
import { SaleContextFilter } from "graphql-client/queries/app";
import useAppData from "hooks/useAppData";
import { useCurrencyCode } from "hooks/useCurrencyCode";
import { useIsoCookies } from "hooks/useIsoCookies";
import useMeasurementType from "hooks/useMeasurementType";
import useParams from "hooks/useParams";
import useSite from "hooks/useSite";
import useUserPreferences from "hooks/useUserPreferences";
import { useFetchParams } from "hooks/useFetchParams/useFetchParams";
import { processEnvServer } from "hooks/useSsrHooks";
import { UseRequestProvider } from "customProviders/Provider";
import { useHistory } from "react-router";
import PDP from "page-pdp";

const PDPSwitcher = props => {
  const { version } = props;
  const { app } = useAppData();
  const locale = useLocale();
  const env = useEnv();
  const { isConcierge } = useAppId();
  const pc = useIsoCookies(["pc"])?.pc;
  const params = useParams({
    productId: "",
    fullSkuId: "",
    sale: ""
  });
  let history = useHistory();
  const req: { path?: string } = processEnvServer
    ? useContext(UseRequestProvider)
    : {};
  const pathname = processEnvServer ? req?.path : location.pathname;

  //for canonical URL support
  const _params = useFetchParams(
    pathname,
    yn(env.FEATURE_URL_CHANGE) ? "pdp" : "product.jsp"
  );
  let [productId] = _params;
  if (params.productId === "") {
    params.productId = productId?.replace("&ct=true", "");
  }

  const { userType } = useRhUserAtomValue();

  const siteId = useSite();
  const [isCacheEnabled, setCacheEnabled] = useState(false);
  const { previousState } = useUserPreferences();
  const measureSystem = useMeasurementType();
  const currencyCode = useCurrencyCode({
    country: previousState.country,
    postalCode: app.postalCode || pc,
    userType
  });

  const saleContextParam = useMemo(
    () => (params.sale === "true" ? "sale" : null) as SaleContextFilter,
    [params.sale]
  );

  const productVariables = useMemo(() => {
    return {
      productId: params?.productId,
      filter: saleContextParam,
      userType: userType,
      siteId,
      currencyCode: currencyCode,
      measureSystem,
      locale: locale,
      postalCode: pc || app.postalCode
    };
  }, [
    params.productId,
    saleContextParam,
    userType,
    siteId,
    currencyCode,
    measureSystem,
    locale,
    pc
  ]);

  const {
    loading: isProductLoading,
    data: { product } = {} as Query,
    refetch
  } = useQuery<Query>(queryProduct, {
    variables: productVariables,
    context: {
      fetchOptions: {
        method: "POST"
      }
    },
    skip: !params.productId || version === "v2",
    errorPolicy: "all",
    fetchPolicy:
      !processEnvServer && !isCacheEnabled ? "no-cache" : "cache-and-network",
    onCompleted: data => {
      if (data?.product?.productAddons?.productAddonsInfo && !isCacheEnabled) {
        setCacheEnabled(true);
      }
    }
  });

  useEffect(() => {
    const unlisten = history.listen(location => {
      if (!location?.pathname?.includes("product.jsp") && isCacheEnabled) {
        refetch({
          fetchPolicy: !processEnvServer ? "no-cache" : "cache-and-network"
        });
      }
    });
    return () => {
      unlisten();
    };
  }, [history?.location]);

  if (!isConcierge && product?.conciergeOnlyForNewPDP) {
    memoryStorage.setItem("newPdpLayout", false);
  } else if (product?.newPdpLayout) {
    memoryStorage.setItem("newPdpLayout", true);
  } else {
    memoryStorage.setItem("newPdpLayout", false);
  }

  return (
    <PDP
      productItem={product}
      isLoading={isProductLoading}
      key={product?.id || "placeholder"}
    />
  );
};

export default PDPSwitcher;

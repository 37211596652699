import { usePageContent } from "@RHCommerceDev/custom-providers/LocationProvider";
import useBrand from "@RHCommerceDev/hooks-use-brand/useBrand";
import { useRhUserAtomValue } from "@RHCommerceDev/hooks/atoms";
import useAccountNavigation from "@RHCommerceDev/hooks/useAccountNavigation";
import { useAuth } from "@RHCommerceDev/hooks/useAuth";
import { useCountry } from "@RHCommerceDev/hooks/useCountry";
import { useFetchModel } from "@RHCommerceDev/hooks/useFetchModel";
import { useIsTradeOrContractPage } from "@RHCommerceDev/hooks/useIsTradeOrContractPage";
import { useTypographyStyles } from "@RHCommerceDev/hooks/useTypographyStyles";
import { countries } from "@RHCommerceDev/resources/countries-config.json";
import { LOGIN } from "@RHCommerceDev/resources/rh-header.json";
import { withKeyboardEvent } from "@RHCommerceDev/utils/accessibility";
import { checkCountryIsEu } from "@RHCommerceDev/utils/checkCountryIsEu";
import { getAccountNavigation } from "@RHCommerceDev/utils/getAccountNavigation";
import getCountryFromUrl from "@RHCommerceDev/utils/getCountryFromUrl";
import { useKeycloak } from "@RHCommerceDev/utils/Keycloak/KeyCloak";
import {
  createStyles,
  Grid,
  makeStyles,
  MenuItem,
  Theme,
  Typography,
  useMediaQuery,
  useTheme
} from "@RHCommerceDev/utils/material-ui-core";
import memoize from "@RHCommerceDev/utils/memoize";
import classNames from "classnames";
import RHMenuDrawer from "component-rh-menu-drawer";
import ConfirmDialog from "dialog-confirm";
import RHRAccountIcon from "icon-account-rhr";
import RHRArrowIcon from "icon-arrow-rhr";
import React, { CSSProperties, FC, useCallback, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { account } from "./resource.json";

const useStyles = (_darkTheme: boolean | undefined) =>
  makeStyles((theme: Theme) =>
    createStyles({
      arrowIconNavOut: {
        transform: "rotate(90deg)"
      },
      arrowIconNavIn: {
        transform: "rotate(-90deg)"
      },
      menuItem: {
        "& .menu-item-child": {
          paddingBottom: 2,
          borderBottom: "1px solid transparent",
          lineHeight: 1
        },
        "&.MuiListItem-button:hover .menu-item-child": {
          borderBottomColor: "black"
        },

        "&.MuiMenuItem-root": {
          paddingTop: 0,
          paddingBottom: 0
        }
      },
      drawer: {
        "& .MuiDialogContent-root": {
          padding: 40
        },
        [theme.breakpoints.up("xl")]: {
          "& .MuiDialogContent-root": {
            padding: 80
          }
        }
      },

      arrow: {
        fontSize: 7
      },
      iconAccount: {
        width: 14,
        height: 16,
        [theme.breakpoints.up("sm")]: {
          width: 23,
          height: 26
        }
      }
    })
  );

export interface AccountNavMenuProps {
  profileStatus?: ProfileStatus;
  className?: string;
  darkTheme?: boolean;
  style?: CSSProperties;
  /** @deprecated unused */
  rhrAccountIcon?: boolean;
  onClose?: () => void;
}

const RHRAccountIconContainer: FC<
  Omit<AccountNavMenuProps, "className"> & { onClick?: () => void; id?: string }
> = ({ darkTheme, onClick, id }) => {
  const { pageContent } = usePageContent();
  const { userType } = useRhUserAtomValue();
  const { isTradeOrContractPage } = useIsTradeOrContractPage();
  return !(
    isTradeOrContractPage ||
    userType === "CONTRACT" ||
    userType === "TRADE"
  ) ? (
    <Grid
      role="button"
      aria-label={pageContent.HEADER_CONTENT.LOGIN ?? LOGIN}
      onClick={e => {
        e.preventDefault();
        if (onClick) onClick();
      }}
      className={"flex"}
      id="container-accountNavMenu_account-btn"
      data-testid="container-accountNavMenu_account-btn"
      data-analytics-nav="account-icon"
      tabIndex={0}
      onKeyDown={() => {
        if (onClick) withKeyboardEvent(onClick);
      }}
    >
      <RHRAccountIcon
        id={id}
        className={classNames(
          darkTheme ? "text-white" : "",
          `h-4 w-4 xsm:h-6 xsm:w-6 !cursor-pointer`
        )}
      />
    </Grid>
  ) : null;
};

/** account icon not clickable */
const Loading: FC<Omit<AccountNavMenuProps, "className">> = ({ darkTheme }) => {
  return <RHRAccountIconContainer darkTheme={darkTheme} id="loading" />;
};
const SignedOut: FC<Omit<AccountNavMenuProps, "className">> = ({
  darkTheme
}) => {
  const { signIn } = useAuth();
  return (
    <RHRAccountIconContainer
      darkTheme={darkTheme}
      onClick={signIn}
      id="signed-out"
    />
  );
};

const SignedIn: FC<Omit<AccountNavMenuProps, "className">> = memoize(
  ({ darkTheme = false, onClose }) => {
    const showMemberColumn = countries?.[getCountryFromUrl() || "US"]?.member;

    const [menuOpen, setMenuOpen] = useState(false);
    const [openSignOutDialog, setOpenSignOutDialog] = useState(false);

    const anchorEl = useRef(null);
    const { push: historyPush } = useHistory();
    const { isTradeOrContractPage } = useIsTradeOrContractPage();
    const classes = useStyles(darkTheme)();
    const typographyStyles = useTypographyStyles({
      keys: ["uppercaseText"]
    });

    const closeMenuCb = useCallback(() => {
      setMenuOpen(false), onClose?.();
    }, [onClose]);
    const openMenuCb = useCallback(() => {
      window?.scrollTo({ top: 0 });
      setMenuOpen(true);
    }, []);

    const { firstName, userType } = useRhUserAtomValue();
    const { signOut, signedOut, loading } = useAuth();
    const theme = useTheme();
    const { inlineAccountNavigation } = useAccountNavigation();
    const country = useCountry();
    const brand = useBrand();
    const countryIsEu = checkCountryIsEu({ country });

    const { pageContent } = useFetchModel("admin/home", true);

    const onMenuItemClickCb = useCallback(
      child => {
        if (child.id === "/signout") {
          return setOpenSignOutDialog(true);
        }

        historyPush(child.targetUrl);

        closeMenuCb();
      },
      [closeMenuCb, historyPush]
    );
    const smDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("xs"));

    return (
      <>
        <ConfirmDialog
          title={pageContent?.authPages?.signOutConfirm}
          loading={loading}
          buttonText={pageContent?.authPages?.signOut}
          onClick={signOut}
          open={openSignOutDialog}
          onClose={() => setOpenSignOutDialog(false)}
          succeed={signedOut}
          succeedText={pageContent?.authPages?.succeed}
        />
        {!(
          isTradeOrContractPage ||
          userType === "CONTRACT" ||
          userType === "TRADE"
        ) && (
          <div
            className={"flex items-center cursor-pointer"}
            onClick={openMenuCb}
            role="button"
            onKeyDown={withKeyboardEvent(openMenuCb)}
            ref={anchorEl}
            data-analytics-nav="account-icon"
            id="container-accountNavMenu_account-btn"
            data-testid="container-accountNavMenu_account-btn"
            aria-label="Account"
            tabIndex={0}
          >
            <RHRAccountIcon
              className={classNames(
                darkTheme ? "text-white" : "",
                `!h-4 !w-4 sm:!h-[26px] sm:!w-[26px] !cursor-pointer`
              )}
            />
          </div>
        )}
        <RHMenuDrawer
          open={menuOpen}
          isDark={true}
          title={pageContent?.accountMenu?.myAccount || account.my_account}
          anchor={smDown ? "left" : "right"}
          PaperProps={{
            className: `overflow-visible w-[75vw] sm:w-[50vw] md:w-[33.33vw] xl:w-[25vw] ${classes.drawer}`
          }}
          onClose={closeMenuCb}
          subTitle={`${
            pageContent?.accountMenu?.welcomeBack || account.welcome_back
          } ${firstName ? firstName?.toUpperCase() : ""}`}
        >
          {getAccountNavigation(inlineAccountNavigation, showMemberColumn).map(
            child =>
              !(
                (child?.targetUrl?.includes("gift-registry") &&
                  (brand === "TN" || countryIsEu)) ||
                (child?.targetUrl?.includes("wish-list") && countryIsEu)
              ) ? (
                <MenuItem
                  key={`account-menu-link-${child.id}`}
                  id={`account-menu-link-${child.id}`}
                  disableGutters
                  onClick={() => onMenuItemClickCb(child)}
                  style={{
                    backgroundColor: "transparent",
                    marginTop: 12,
                    width: "100%"
                  }}
                  className={`uppercase ${classes.menuItem}`}
                  data-navigation-account-item="true"
                  data-navigation-account-item-id={child.id}
                  data-testid={`navigation-account-item-id-${child.id.replace(
                    "/",
                    ""
                  )}`}
                >
                  <Grid
                    container
                    justify="space-between"
                    style={{ width: "100%" }}
                  >
                    <Typography
                      variant={smDown ? "h4" : "caption"}
                      className={typographyStyles.uppercaseText}
                    >
                      {pageContent?.accountMenu?.[child.labelCode] ||
                        child.displayName}
                    </Typography>
                    {smDown && (
                      <Grid item>
                        <RHRArrowIcon
                          strokeColor={theme.palette.common.black}
                          className={"!text-[7px]"}
                        />
                      </Grid>
                    )}
                  </Grid>
                </MenuItem>
              ) : null
          )}
        </RHMenuDrawer>
      </>
    );
  }
);

export const AccountNavMenu: FC<AccountNavMenuProps> = ({
  profileStatus,
  className,
  darkTheme,
  style,
  onClose
}) => {
  const { keycloak } = useKeycloak();
  const { userType, loading } = useRhUserAtomValue();
  const userTypes = ["REGISTERED", "TRADE", "CONTRACT"];
  const authStatus =
    (keycloak?.authenticated || (userType && userTypes.includes(userType))) ??
    false;
  return (
    <Grid style={style} item className={className}>
      {loading ? (
        <Loading darkTheme={darkTheme} />
      ) : authStatus ? (
        <SignedIn
          darkTheme={darkTheme}
          profileStatus={profileStatus}
          onClose={onClose}
        />
      ) : (
        <SignedOut darkTheme={darkTheme} />
      )}
    </Grid>
  );
};

export default memoize(AccountNavMenu);

import classNames from "classnames";
import React from "react";
import {
  formatCurrencyPrice,
  isNullOrUndefined
} from "@RHCommerceDev/component-rh-price-range-display/helpers";
import { PriceProps } from "@RHCommerceDev/component-rh-price-range-display/types";

const Price = (props: PriceProps) => {
  const { listPrice, topLabel } = props;

  return !isNullOrUndefined(listPrice) ? (
    <div
      className={classNames(
        `flex flex-col justify-center pr-2.5 w-full box-border xs:justify-start items-start`
      )}
    >
      <div className={classNames(`flex flex-row items-baseline flex-wrap`)}>
        {topLabel ? (
          <>
            <span
              className={classNames(
                "my-0 mr-1 text-rh-darker text-[13px] font-thin font-primary-rhthin leading-[150%] tracking-[0.52px] uppercase"
              )}
            >
              {topLabel}
            </span>
          </>
        ) : null}
        <span
          className={classNames(
            `text-rh-darker text-[13px] font-thin font-primary-rhthin leading-[150%] tracking-[0.52px] uppercase`
          )}
        >
          {formatCurrencyPrice(listPrice)}
        </span>
      </div>
    </div>
  ) : (
    <></>
  );
};
export default React.memo(Price);

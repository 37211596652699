import React, { FC, useEffect, useState, useContext } from "react";
import { DrawerHeaderProps } from "container-drawer-header/types";
import { InlineHeaderProps } from "container-inline-header/types";
import { RHRHeaderProps } from "container-rhr-header/types";
import { useLocation } from "react-router-dom";
import { useIsoCookies } from "hooks/useIsoCookies";
import {
  Grid,
  Link,
  Theme,
  Typography,
  useMediaQuery,
  useTheme
} from "utils/material-ui-core";
import memoize from "utils/memoize";
import { useEnv } from "hooks/useEnv";
import RHRHeader from "container-rhr-header";
import DrawerHeader from "container-drawer-header";
import InlineHeader from "container-inline-header";
import { v4 as uuidv4 } from "uuid";
import { useParams2 } from "hooks/useParams";

import { ProductConfiguratorContext } from "@RHCommerceDev/custom-providers/ProductConfiguratorProvider";
import { usePageContent } from "customProviders/LocationProvider";
import useIsHomePage from "hooks/useIsHomePage";
import { SKIP_TO_MAIN } from "resources/rh-header.json";
import { processEnvServer } from "hooks/useSsrHooks";

// // lazy loaded components
// const RHRHeader = loadable(
//   () =>
//     import(
//       /* webpackChunkName: "container-rhr-header" */ "container-rhr-header"
//     )
// );
// const DrawerHeader = loadable(
//   () =>
//     import(
//       /* webpackChunkName: "container-drawer-header" */ "container-drawer-header"
//     ),
//   {
//     fallback: <></>
//   }
// );
// const InlineHeader = loadable(
//   () =>
//     import(
//       /* webpackChunkName: "container-inline-header" */ "container-inline-header"
//     ),
//   {
//     fallback: <></>
//   }
// );
const hideRoutes = [
  "/checkout/checkout-sign-in-register.jsp",
  "/checkout/signin.jsp",
  "/checkout/address.jsp",
  "/checkout/payment.jsp",
  "/checkout/update-payment.jsp",
  "/checkout/order_review.jsp",
  "/checkout/thank_you.jsp",
  "/checkout/confirmation.jsp",
  "/checkout/contract-payment.jsp",
  "/checkout/update-card.jsp"
];

export interface HeaderSwitcherProps {
  InlineHeaderProps?: InlineHeaderProps;
  DrawerHeaderProps?: DrawerHeaderProps;
  RHRHeaderProps?: RHRHeaderProps;
  isAem?: boolean;
  isInEditor?: boolean;
}

export const HeaderSwitcher: FC<HeaderSwitcherProps> = ({
  InlineHeaderProps,
  DrawerHeaderProps,
  RHRHeaderProps,
  ...rest
}) => {
  const env = useEnv();
  const { pathname } = useLocation();
  const deviceType = useIsoCookies(["PF_EXP"])?.PF_EXP || "DESKTOP";
  const theme = useTheme();
  const { pageContent } = usePageContent();
  const isHomePage = useIsHomePage();
  // mobile device and < 992px on desktop
  const sm = useMediaQuery<Theme>(theme => {
    return theme.breakpoints.down("sm");
  });
  const [reloadKey, setReloadKey] = useState(null);
  const { params: queryParams } = useParams2<{ [key: string]: string }>(
    { version: "" },
    { toLowerCase: true }
  );
  const location = useLocation();
  const isProductPage = location.pathname.includes("product/product.jsp");

  const handleSkipToMainContent = e => {
    if (!processEnvServer) {
      e.preventDefault();
      const element = document.getElementById(
        isHomePage ? "component-link" : "main"
      );
      if (element && element.nextElementSibling && isHomePage) {
        element.nextElementSibling.scrollIntoView({
          behavior: "smooth",
          block: "center"
        });
        element.focus();
      } else if (element) {
        const focusableElements =
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
        const firstFocusableElement = element.querySelector(
          focusableElements
        ) as HTMLElement;
        if (firstFocusableElement) {
          firstFocusableElement.scrollIntoView({
            behavior: "smooth",
            block: "center"
          });
          firstFocusableElement.focus();
        }
      }
    }
  };

  useEffect(() => {
    // force a re-render on client side for problematic session

    if (!reloadKey) {
      setReloadKey(uuidv4());
    }
  }, []);

  let { newPDPLayout } = useContext(ProductConfiguratorContext);

  return hideRoutes.includes(pathname) ? null : (
    <>
      <Link
        onClick={handleSkipToMainContent}
        onKeyDown={e => e.key === "Enter" && handleSkipToMainContent(e)}
        className="skip-to-main-content-link"
        tabIndex={0}
      >
        <Typography>
          {pageContent?.HEADER_CONTENT?.SKIP_TO_PAGE_CONTENT ?? SKIP_TO_MAIN}
        </Typography>
      </Link>
      <Grid
        id="rh-header"
        container
        style={{
          zIndex: theme.zIndex.appBar + 1,
          position: "relative"
        }}
      >
        <>
          {env.FEATURE_WORH ? (
            <RHRHeader
              {...RHRHeaderProps}
              {...rest}
              key={(reloadKey ? reloadKey : 123) + "header"}
              renderingFrom="headerSwitcher"
            />
          ) : deviceType === "MOBILE" || sm ? (
            <DrawerHeader {...DrawerHeaderProps} {...rest} />
          ) : (
            <InlineHeader {...InlineHeaderProps} {...rest} />
          )}
        </>
      </Grid>
    </>
  );
};

export default memoize(HeaderSwitcher);

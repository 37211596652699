import React from "react";
import memoize from "@RHCommerceDev/utils/memoize";
import CloseIcon from "./CloseIcon";
import { SwatchDetailPanelProps } from "./types";

const SwatchDetailPanel: React.FC<SwatchDetailPanelProps> = ({
  swatch,
  onClose
}) => {
  if (!swatch) return null;

  return (
    <div
      className="h-64 w-full relative"
      style={{
        background: `url('${swatch?.swatchImageLg?.url}') top center / contain`
      }}
    >
      <div className="relative flex p-4 w-full flex-col flex-1 gap-6 h-full">
        {!!swatch?.optionCopy ? (
          <div
            className={
              "text-white [&>strong]:text-4xl [&>strong]:leading-9 [&>strong]uppercase"
            }
            dangerouslySetInnerHTML={{
              __html: `${swatch?.optionCopy}`
            }}
          />
        ) : (
          <div className="flex-1 flex flex-col gap-6 ">
            <div className="flex flex-col gap-1.5">
              <div className="flex justify-between items-center">
                <h3 className="m-0 text-white text-4xl leading-9 uppercase ">
                  {swatch?.swatchGroupName}
                </h3>
              </div>
              <p className="m-0 text-white text-lg leading-5 uppercase">
                {swatch?.title}
              </p>
            </div>
          </div>
        )}
        <CloseIcon
          className="text-white text-xl absolute top-2 right-2 cursor-pointer"
          onClick={onClose}
        />

        <button className="flex text-white flex-start border-none bg-transparent uppercase p-0 text-xs border-b border-black">
          Add Swatch to cart
        </button>
      </div>
    </div>
  );
};

export default memoize(SwatchDetailPanel);

import React from "react";
import classNames from "classnames";
import memoize from "@RHCommerceDev/utils/memoize";
import RHImage from "@RHCommerceDev/component-rh-image";
import RHInfoIconV2 from "@RHCommerceDev/icon-info-v2";
import { SwatchCarouselProps } from "./types";

const SwatchCarousel: React.FC<SwatchCarouselProps> = ({
  swatches,
  currentSelectedSwatch,
  itemsToShow,
  height,
  width,
  SwatchDetail,
  carouselRef,
  endRef,
  handleMouseLeave,
  handleImageClick,
  handleMouseDown,
  handleMouseUp,
  handleMouseMove,
  handleImageDragStart,
  handleShowInfo
}) => {
  const baseClasses = "border-t-[1.5px] border-b-[1.5px] border-transparent";
  const beforeClasses =
    "before:absolute before:bg-white before:inset-x-0 before:top-0 before:opacity-20 before:h-1 before:z-10";
  const afterClasses =
    "after:absolute after:bg-white after:inset-x-0 after:bottom-0 after:opacity-20 after:h-1 after:z-10";
  const selectedSwatchClass = `!border-black ${beforeClasses} ${afterClasses}`;

  return (
    <div
      className={classNames(
        "w-full overflow-hidden whitespace-nowrap flex items-center relative active:cursor-grabbing",
        { ["hidden"]: SwatchDetail }
      )}
      ref={carouselRef}
      onMouseLeave={handleMouseLeave}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
    >
      <div className="flex transition-all duration-200 ease-linear">
        {swatches.slice(0, itemsToShow).map((swatch, index) => {
          const isSelected =
            currentSelectedSwatch?.swatchId === swatch?.swatchId;
          return (
            <div
              id={`swatch-${index}`}
              className="relative"
              onClick={() => handleImageClick(swatch)}
              key={swatch?.swatchId}
              onDragStart={handleImageDragStart}
            >
              <RHImage
                src={swatch?.swatchImageLg?.url}
                alt={swatch?.title}
                imgStyle={{ height, width }}
                imageWrapperProps={{ style: { width } }}
                objectFit="cover"
                loading="lazy"
                imageWrapperClassName={classNames([
                  baseClasses,
                  { [selectedSwatchClass]: isSelected }
                ])}
              />
              <div className="absolute w-full h-full opacity-0 hover:opacity-100 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex items-center justify-center">
                <div className="flex flex-col bg-white p-2 justify-center items-center gap-1">
                  <h6 className="m-0 text-xs text-black font-primary-rhsans text-center font-normal leading-4 tracking-[0.24px] whitespace-normal w-24">
                    {swatch?.swatchGroupName}
                  </h6>
                  <span className="text-xs text-black font-primary-rhthin text-center leading-4 tracking-[0.24px] w-24">
                    {swatch?.title}
                  </span>
                  <RHInfoIconV2
                    className="cursor-pointer"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      handleShowInfo(swatch);
                    }}
                  />
                </div>
              </div>
            </div>
          );
        })}
        <div ref={endRef} />
      </div>
    </div>
  );
};

export default memoize(SwatchCarousel);

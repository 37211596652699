import React from "react";
import memoize from "utils/memoize";

type DotButtonPropType = {
  selected: boolean;
  index: number;
  selectedIndex: number;
  totalDots: any;
  reverseIndex: number;
  isReverse: boolean;
};

const DotButton: React.FC<DotButtonPropType> = ({
  selected,
  index,
  selectedIndex,
  totalDots,
  reverseIndex,
  isReverse
}) => {
  let isFwdSmall2 = false;
  let isBwdSmall2 = false;

  const isFwdSmall1 =
    index > selectedIndex &&
    (index == 4 || index >= 5) &&
    totalDots > 5 &&
    index != totalDots - 1;

  if (totalDots > 5) {
    if (selectedIndex == totalDots - 1) {
      isFwdSmall2 = selectedIndex >= 4 && index == selectedIndex - 4;
    } else {
      isFwdSmall2 =
        selectedIndex >= 4 &&
        (index == selectedIndex - 3 || index == selectedIndex - 4);
    }
  }

  const isBwdSmall1 =
    index < selectedIndex &&
    totalDots > 5 &&
    (selectedIndex >= totalDots - 3 && reverseIndex == totalDots - 1
      ? index == reverseIndex - 4
      : index <= reverseIndex - 3) &&
    index != 0 &&
    index != totalDots - 1 &&
    selectedIndex != 0;

  if (totalDots > 5 && index != totalDots - 1) {
    if (selectedIndex >= 2) {
      if (selectedIndex <= reverseIndex - 3) {
        if (selectedIndex > 0) {
          isBwdSmall2 =
            index == selectedIndex + 3 || index == selectedIndex + 4;
        } else {
          isBwdSmall2 = index == selectedIndex + 4;
        }
      } else {
        isBwdSmall2 = index == reverseIndex + 1;
      }
    } else {
      isBwdSmall2 = isFwdSmall1 || isFwdSmall2;
    }
  }

  return !isReverse ? (
    <span
      role="button"
      className={`${
        selected ? "opacity-100" : "opacity-50"
      } border-none bg-transparent  transition-opacity duration-300 h-2 flex items-center justify-center`}
    >
      {!selected && (isFwdSmall1 || isFwdSmall2) ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="5"
          height="6"
          viewBox="0 0 5 6"
          fill="none"
        >
          <circle cx="2.5" cy="3.44531" r="1.75" fill="black" />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="5"
          height="6"
          viewBox="0 0 5 6"
          fill="none"
        >
          <circle cx="2.5" cy="3.44531" r="2.5" fill="black" />
        </svg>
      )}
    </span>
  ) : (
    <span
      role="button"
      className={`${
        selected ? "opacity-100" : "opacity-50"
      } border-none bg-transparent transition-opacity duration-300 h-2 flex items-center justify-center`}
    >
      {!selected && (isBwdSmall1 || isBwdSmall2) ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="5"
          height="6"
          viewBox="0 0 5 6"
          fill="none"
        >
          <circle cx="2.5" cy="3.44531" r="1.75" fill="black" />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="5"
          height="6"
          viewBox="0 0 5 6"
          fill="none"
        >
          <circle cx="2.5" cy="3.44531" r="2.5" fill="black" />
        </svg>
      )}
    </span>
  );
};

export default memoize(DotButton);

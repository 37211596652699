import React from "react";
import memoize from "@RHCommerceDev/utils/memoize";
import SwatchDetailPanel from "./SwatchDetailPanel";
import SwatchCarousel from "./SwatchCarousel";
import { usePDPSwatchCarouselData } from "./usePDPSwatchCarouselData";
import MaterialInfo from "./MaterialInfo";
import { PDPProductDetailsProps } from "./types";

const PDPSwatchCarousel: React.FC<PDPProductDetailsProps> = props => {
  const {
    carouselRef,
    currentSwatches,
    currentSelectedSwatch,
    SwatchDetail,
    selectedMaterialName,
    processedSwatches,
    processedFinishSwatches,
    itemsToShow,
    height,
    width,
    endRef,
    handleMouseUp,
    handleMouseDown,
    handleMouseLeave,
    handleMouseMove,
    handleImageDragStart,
    handleImageClick,
    setSelectedMaterialName,
    getBorderClass,
    handleShowInfo,
    setSwatchDetail
  } = usePDPSwatchCarouselData(props);

  return currentSwatches?.swatches?.length ? (
    <div id={"component-pdp-swatch-carousel"}>
      {!SwatchDetail && (
        <MaterialInfo
          selectedMaterialName={selectedMaterialName}
          currentSelectedSwatch={currentSelectedSwatch}
          processedSwatches={processedSwatches}
          processedFinishSwatches={processedFinishSwatches}
          getBorderClass={getBorderClass}
          setSelectedMaterialName={setSelectedMaterialName}
        />
      )}

      <SwatchCarousel
        swatches={currentSwatches?.swatches as ProductSwatch[]}
        currentSelectedSwatch={currentSelectedSwatch}
        itemsToShow={itemsToShow}
        height={height}
        width={width}
        SwatchDetail={SwatchDetail}
        carouselRef={carouselRef}
        endRef={endRef}
        handleImageClick={handleImageClick}
        handleMouseDown={handleMouseDown}
        handleMouseUp={handleMouseUp}
        handleMouseLeave={handleMouseLeave}
        handleMouseMove={handleMouseMove}
        handleImageDragStart={handleImageDragStart}
        handleShowInfo={handleShowInfo}
      />

      {SwatchDetail && (
        <SwatchDetailPanel
          swatch={SwatchDetail}
          onClose={() => setSwatchDetail(null)}
        />
      )}
    </div>
  ) : null;
};

export default memoize(PDPSwatchCarousel);

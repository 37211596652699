import React, {
  FC,
  ReactElement,
  useEffect,
  useRef,
  useState,
  memo,
  forwardRef,
  useImperativeHandle
} from "react";
import {
  useMediaQuery,
  makeStyles,
  createStyles,
  Theme,
  ButtonBase
} from "utils/material-ui-core";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useInView } from "react-intersection-observer";
import classNames from "classnames";
import { ArrowsSize, ArrowsVisible, DotsVisible } from "./types";
import NextArrow from "./NextArrow";
import PreviousArrow from "./PreviousArrow";
import { handleKeyboardPressWithEvent } from "utils/accessibility";
import CarouselArrowIcon from "icons/CarouselArrowIcon";
import { BREAKPOINT_XL, BREAKPOINT_MD, BREAKPOINT_SM } from "utils/constants";

const AUTOPLAY_SPEED = 6000;
const DEFAULT_ARROWS_SIZE = "large";
const DEFAULT_ARROWS_VISIBLE = "hover-only";
const DEFAULT_DOTS_VISIBLE = "hover-only";
const VISIBLE_DOTS = 5;

export const SPEED_MULTIPLIER = 100;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dotsNoTransform: {
      width: "auto !important",
      "& .react-carousel-dots-dot, & > li": {
        "&.small": {
          transform: "scale(1)"
        },
        "&.medium": {
          transform: "scale(1)"
        }
      }
    },
    dotsBlack: {
      "& .react-carousel-dots-dot, & > li": {
        border: 0,
        "&.active": {
          opacity: 1
        }
      }
    },
    dotsNoBorder: {
      "& .react-carousel-dots-dot, & > li": {
        border: 0
      }
    },
    dotGridV2: {
      "& > div": {
        maxWidth: "48px",
        overflow: "hidden"
      },
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
      "& .react-carousel-dots-holder": {
        "& .dot-holder": {
          width: "5.16px !important",
          height: "5.16px !important",

          "& > li": {
            "&.medium": {
              transform: "unset"
            },
            "&.small": {
              transform: "unset"
            },
            backgroundColor: "#DEDAD4",
            border: "0.25px solid #99958F",

            "&.slick-active": {
              border: "0.25px solid #99958F",
              backgroundColor: "#fff !important"
            }
          }
        }
      }
    },
    dotsRootV2: {
      margin: 0,
      padding: 0,
      display: "flex",
      gap: "4px",
      alignItems: "center",
      "& > li": {
        "& > svg": {
          "& > circle:nth-child(2)": {
            display: "none"
          }
        },
        margin: "unset",
        transform: "scale(1)",
        "&.small": {
          transform: "scale(0.5)"
        },
        "&.medium": {
          transform: "scale(0.75)"
        },
        "&.small-dot": {
          "& > svg": {
            "& > circle:nth-child(2)": {
              display: "none"
            }
          }
        },

        "&.slick-active": {
          "& > svg": {
            "& > circle:nth-child(2)": {
              display: "unset"
            }
          }
        }
      }
    },
    dotsRootV3: {
      gap: "19px",
      [theme.breakpoints.between(BREAKPOINT_SM, BREAKPOINT_MD)]: {
        gap: "21.6px"
      },
      "& > li": {
        "& > svg": {
          [theme.breakpoints.between(BREAKPOINT_SM, BREAKPOINT_MD)]: {
            height: "11.4px",
            width: "11.4px"
          }
        },
        height: "10px",
        width: "10px",
        [theme.breakpoints.between(BREAKPOINT_SM, BREAKPOINT_MD)]: {
          height: "11.4px",
          width: "11.4px"
        },
        display: "flex"
      }
    },
    arrowOutside: {
      "& .slick-slider .arrow-container": {
        padding: 0,
        "&:first-child": {
          [theme.breakpoints.up(BREAKPOINT_MD)]: {
            left: "-36px !important"
          },
          [theme.breakpoints.up(BREAKPOINT_XL)]: {
            left: "-56px !important"
          }
        },
        "&:last-child": {
          [theme.breakpoints.up(BREAKPOINT_MD)]: {
            right: "-36px !important"
          },
          [theme.breakpoints.up(BREAKPOINT_XL)]: {
            right: "-56px !important"
          }
        }
      }
    },
    videodisplay: {
      "& > div > div > div": {
        display: "flex !important",
        alignItems: "center !important"
      }
    }
  })
);

export type RHCarouselArrowStyles = {
  carouselArrowStyles?: React.CSSProperties;
  carouselArrowGridStyles?: React.CSSProperties;
};

type AccessibilityProps = {
  role: string;
  "aria-label": string;
};

export interface RHCarouselProps extends Settings {
  children: ReactElement[];
  onChangeIndex?: (i: number) => void;
  index?: number;
  visibleDots?: number;
  resetOffScreen?: boolean;
  nextArrowStyles?: RHCarouselArrowStyles;
  prevArrowStyles?: RHCarouselArrowStyles;
  arrowPlacementIsOutside?: boolean;
  arrowsColor?: string;
  arrowsBackgroundColor?: string;
  darkDotTheme?: boolean;
  arrowWidth?: number;
  arrowsSize?: ArrowsSize;
  arrowZoomImage?: boolean;
  arrowsVisible?: ArrowsVisible;
  dotsVisible?: DotsVisible;
  allowSlickDisabledClass?: boolean;
  originalDots?: boolean;
  [key: string]: any;
  classess?: any;
  icon?: string;
  imageCarousels?: boolean;
  sliderSpacing?: any;
  appendDotsFn?: (dots: any) => JSX.Element;
  handleOnNextArrowClick?: (index: number) => void;
  handleOnPreviousArrowClick?: (index: number) => void;
  isNewPdpLayout?: boolean;
  parentBaseId?: string;
  isNewPdpDots?: boolean;
  prevArrowAccessibilityProps?: AccessibilityProps;
  nextArrowAccessibilityProps?: AccessibilityProps;
}

const PX_DIFF = 10;

export const RHCarousel: FC<RHCarouselProps> = forwardRef(
  (
    {
      children,
      onChangeIndex,
      index = 0,
      visibleDots = VISIBLE_DOTS,
      autoplay = false,
      autoplaySpeed = AUTOPLAY_SPEED,
      pauseOnHover = false,
      speed = 9, // represents tenths of a second
      resetOffScreen = false,
      nextArrowStyles,
      prevArrowStyles,
      classess,
      icon,
      infinite,
      arrowPlacementIsOutside,
      arrowsColor,
      arrowsBackgroundColor,
      darkDotTheme,
      arrowWidth,
      arrowsSize = DEFAULT_ARROWS_SIZE,
      arrowsVisible = DEFAULT_ARROWS_VISIBLE,
      dotsVisible = DEFAULT_DOTS_VISIBLE,
      arrowZoomImage,
      imageCarousels,
      sliderSpacing,
      allowSlickDisabledClass = false,
      originalDots = false,
      appendDotsFn,
      handleOnNextArrowClick,
      handleOnPreviousArrowClick,
      isNewPdpLayout,
      parentBaseId,
      isNewPdpDots = false,
      nextArrowAccessibilityProps,
      prevArrowAccessibilityProps,
      ...rest
    },
    externalRef
  ) => {
    const baseId = `${parentBaseId}-RHCarousel`;
    const desktopMatchesQuery = useMediaQuery((theme: Theme) =>
      theme.breakpoints.up("md")
    );

    const classes = useStyles();

    const arrowPlacementClass = arrowPlacementIsOutside
      ? classes.arrowOutside
      : "";

    const [active, setActive] = useState(index);

    const sliderRef = useRef<Slider | null>(null);

    const [ref, inView] = useInView();
    const ulRef = useRef<HTMLUListElement>(null);

    const prevValue = useRef({
      active: 0,
      first: 0,
      last: 5,
      px: 0,
      dotsLength: 0
    });

    const [slidePosition, setSlidePosition] = useState(0);
    const totalSlides = React.Children.count(children);

    const handleBeforeChange = (_, next) => {
      setSlidePosition(next);
    };

    useEffect(() => {
      const { first, last, px, dotsLength } = prevValue.current;
      let _first = first;
      let _last = last;
      let _px = px;
      if (active > first && active < last - 1) {
      } else if (active === 0) {
        _first = 0;
        _last = 5;
        _px = 0;
      } else if (active === dotsLength - 1) {
        _first = dotsLength - 5;
        _last = dotsLength;
        _px = (dotsLength - 5) * PX_DIFF;
      } else if (active === first) {
        _first = first - 1;
        _last = last - 1;
        _px = px + PX_DIFF;
      } else if (active === last - 1) {
        _first = first + 1;
        _last = last + 1;
        _px = px - PX_DIFF;
      } else if (active < first) {
        _first = active - 1;
        _last = active + 4;
        _px = (active - 1) * PX_DIFF;
      } else if (active >= last) {
        _first = active - 3;
        _last = active + 1;
        _px = (active - 3) * PX_DIFF;
      }

      if (_px > 0) {
        _px = -_px;
      }

      prevValue.current = {
        ...prevValue.current,
        active,
        first: _first,
        last: _last,
        px: _px
      };
    }, [active]);

    useEffect(() => {
      function onSliderInViewChange() {
        if (!rest.disableInView && inView) {
          if (autoplay) sliderRef.current?.slickPlay();
        } else if (rest.disableInView) {
          sliderRef.current?.slickPlay();
        } else {
          if (autoplay && !rest.disableInView) sliderRef.current?.slickPause();
          if (resetOffScreen) {
            setActive(0);
            onChangeIndex?.(0);
          }
        }
      }

      onSliderInViewChange();
    }, [
      inView,
      autoplay,
      resetOffScreen,
      sliderRef,
      onChangeIndex,
      rest.disableInView
    ]);

    useEffect(() => {
      sliderRef.current?.slickGoTo(index);
    }, [index]);

    useImperativeHandle(externalRef, () => ({
      sliderRef,
      flipTo: slideIndex => sliderRef.current?.slickGoTo(slideIndex)
    }));

    const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));

    return (
      <div
        style={{ willChange: "transform, opacity", marginBottom: "-1px" }}
        ref={ref}
        className={
          imageCarousels
            ? classes?.videodisplay
            : `${sliderSpacing} ${arrowPlacementClass}`
        }
        data-analytics-id={`rh-carousels`}
        id={`${baseId}-rh-carousels`}
        data-testid={`${baseId}-rh-carousels`}
        key={`${baseId}-rh-carousels`}
      >
        <Slider
          draggable={!mdUp ? true : false}
          speed={speed * SPEED_MULTIPLIER}
          autoplay={autoplay && inView}
          autoplaySpeed={autoplaySpeed}
          easing="linear"
          infinite={infinite ?? autoplay}
          pauseOnHover={arrowZoomImage && !mdUp ? false : pauseOnHover}
          lazyLoad="progressive"
          {...rest}
          ref={sliderRef}
          className={classess}
          initialSlide={index}
          dots={
            isNewPdpLayout && !isNewPdpDots
              ? true
              : (rest.persistDots || !desktopMatchesQuery) &&
                children?.length > 1
          }
          arrows={
            isNewPdpLayout && !isNewPdpDots
              ? true
              : desktopMatchesQuery && children?.length > 1
          }
          beforeChange={handleBeforeChange}
          afterChange={onAfterChange}
          accessibility={false}
          customPaging={item =>
            isNewPdpLayout && !isNewPdpDots ? (
              item == active ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                >
                  <circle cx="5" cy="4.99805" r="5" fill="black" />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                >
                  <circle cx="5" cy="4.99805" r="4.5" stroke="#898886" />
                </svg>
              )
            ) : (
              <svg
                width="6"
                height="6"
                viewBox="0 0 7 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                tabIndex={0}
              >
                <circle
                  cx="3"
                  cy="3"
                  r="2.75"
                  fill="#ADACAA"
                  stroke="#898886"
                  stroke-width="0.5"
                />
                <circle cx="3" cy="3" r="2.75" fill="#201F1F" />
              </svg>
            )
          }
          {...(originalDots
            ? {}
            : {
                appendDots: isNewPdpLayout
                  ? dots => appendDotsPDPV3(dots)
                  : appendDotsFn ?? (dots => appendDots(dots)),
                dotsClass: `slick-dots ${classes.dotGridV2} grid justify-center`
              })}
          nextArrow={
            arrowsVisible === "never" ? (
              <></>
            ) : (
              <NextArrow
                parentBaseId={`${baseId}-nextArrow`}
                key={`${baseId}-nextArrow`}
                className={"carouselArrow"}
                style={{ display: "none" }}
                allowDisabledClass={allowSlickDisabledClass}
                {...nextArrowStyles}
                arrowsColor={
                  arrowZoomImage ||
                  (slidePosition >= totalSlides - (rest?.slidesToShow || 5) &&
                    !infinite)
                    ? "rgba(0, 0, 0, 0.54)"
                    : arrowsColor
                }
                arrowsBackgroundColor={arrowsBackgroundColor}
                arrowsSize={arrowsSize}
                arrowWidth={arrowWidth}
                arrowsVisible={arrowsVisible}
                dotsVisible={dotsVisible}
                arrowZoomImage={arrowZoomImage}
                imageCarousels={imageCarousels}
                icon={icon}
                handleNext={() => handleOnNextArrowClick?.(index)}
                tabIndex={0}
                onKeyDown={handleKeyboardPressWithEvent(
                  handleOnNextArrowClick?.(index)
                )}
                nextArrowAccessibilityProps={nextArrowAccessibilityProps}
              />
            )
          }
          prevArrow={
            arrowsVisible === "never" ? (
              <></>
            ) : (
              <PreviousArrow
                parentBaseId={baseId}
                key={`${baseId}-previousArrow`}
                className={"carouselArrow"}
                allowDisabledClass={allowSlickDisabledClass}
                {...prevArrowStyles}
                arrowsColor={
                  arrowZoomImage || (slidePosition === 0 && !infinite)
                    ? "rgba(0, 0, 0, 0.54)"
                    : arrowsColor
                }
                arrowsBackgroundColor={arrowsBackgroundColor}
                arrowsSize={arrowsSize}
                arrowsVisible={arrowsVisible}
                dotsVisible={dotsVisible}
                arrowZoomImage={arrowZoomImage}
                imageCarousels={imageCarousels}
                icon={icon}
                handlePrev={() => handleOnPreviousArrowClick?.(index)}
                tabIndex={0}
                onKeyDown={handleKeyboardPressWithEvent(
                  handleOnPreviousArrowClick?.(index)
                )}
                prevArrowAccessibilityProps={prevArrowAccessibilityProps}
              />
            )
          }
          {...rest}
        >
          {React.Children.map(children, (child, idx) => {
            const position = idx + 1;
            return React.cloneElement(child, {
              slideposition: `slide${position < 10 ? "0" : ""}${position}`
            });
          })}
        </Slider>
      </div>
    );

    function onAfterChange(current: number) {
      setActive(current);
      onChangeIndex?.(current);
    }

    function appendDots(dots: any = null) {
      let INDEX_MAPPING = {};
      dots
        .slice(4)
        .forEach((d, i) => (INDEX_MAPPING[i * PX_DIFF] = [d.key - 4, +d.key]));

      let _px = 0;
      const activeIndex = Number(
        dots.find(el => el.props.className === "slick-active")?.key
      );
      if (dots.length > 5) {
        prevValue.current = {
          ...prevValue.current,
          dotsLength: dots?.length
        };
        const { first, last, px, active } = prevValue.current;
        _px = px;
        if (active !== activeIndex) {
          if (activeIndex === 0) {
            _px = 0;
          } else if (activeIndex === dots.length - 1) {
            _px = (dots.length - 5) * PX_DIFF;
          } else if (activeIndex > first && activeIndex < last - 1) {
          } else if (activeIndex === first) {
            _px = px + PX_DIFF;
          } else if (activeIndex === last - 1) {
            _px = px - PX_DIFF;
          } else if (activeIndex < first) {
            _px = (activeIndex - 1) * PX_DIFF;
          } else if (activeIndex >= last) {
            _px = (activeIndex - 3) * PX_DIFF;
          }

          if (_px > 0) {
            _px = -_px;
          }
        }

        if (ulRef.current) {
          const children = ulRef.current.children;
          for (let i = 0; i < dots.length; i++) {
            children[i].classList.remove("small-dot");
          }

          let calFirst = Math.abs(_px) / 10;
          _px < 0 && children[calFirst].classList.add("small-dot");
          Math.abs(_px) !== Math.abs((dots.length - 5) * PX_DIFF) &&
            children[calFirst + 4]?.classList?.add("small-dot");
        }
      }
      return (
        <div className={`grid justify-center`} id={"component-rh-carousel"}>
          <div>
            <ul
              ref={ulRef}
              style={{
                transform: `translateX(${_px}px)`,
                transition: "0.2s"
              }}
              id={"dotsRootV2"}
              className={classNames({
                [classes.dotsRootV2]: true,
                ["hidden"]: dotsVisible === "never",
                [classes.dotsNoTransform]: children.length <= visibleDots,
                [classes.dotsBlack]: darkDotTheme,
                [classes.dotsNoBorder]: false
              })}
            >
              {dots}
            </ul>
          </div>
        </div>
      );
    }

    function appendDotsPDPV3(dots: any = null) {
      let INDEX_MAPPING = {};
      dots
        .slice(4)
        .forEach((d, i) => (INDEX_MAPPING[i * PX_DIFF] = [d.key - 4, +d.key]));

      let _px = 0;
      const activeIndex = Number(
        dots.find(el => el.props.className === "slick-active")?.key
      );
      if (dots.length > 5) {
        prevValue.current = {
          ...prevValue.current,
          dotsLength: dots?.length
        };
        const { first, last, px, active } = prevValue.current;
        _px = px;
        if (active !== activeIndex) {
          if (activeIndex === 0) {
            _px = 0;
          } else if (activeIndex === dots.length - 1) {
            _px = (dots.length - 5) * PX_DIFF;
          } else if (activeIndex > first && activeIndex < last - 1) {
          } else if (activeIndex === first) {
            _px = px + PX_DIFF;
          } else if (activeIndex === last - 1) {
            _px = px - PX_DIFF;
          } else if (activeIndex < first) {
            _px = (activeIndex - 1) * PX_DIFF;
          } else if (activeIndex >= last) {
            _px = (activeIndex - 3) * PX_DIFF;
          }

          if (_px > 0) {
            _px = -_px;
          }
        }

        if (ulRef.current) {
          const children = ulRef.current.children;
          for (let i = 0; i < dots.length; i++) {
            children[i].classList.remove("small-dot");
          }

          let calFirst = Math.abs(_px) / 10;
          _px < 0 && children[calFirst].classList.add("small-dot");
          Math.abs(_px) !== Math.abs((dots.length - 5) * PX_DIFF) &&
            children[calFirst + 4]?.classList?.add("small-dot");
        }
      }
      return (
        <div
          className={`container justify-center`}
          id={"component-rh-carousel"}
        >
          <ButtonBase
            id="carousel-arrow-button"
            onClick={e => {
              e?.preventDefault();
              onChangeIndex?.(index - 1);
            }}
          >
            <CarouselArrowIcon style={{ transform: "rotate(180deg)" }} />
          </ButtonBase>
          <div>
            <ul
              ref={ulRef}
              style={{
                transform: `translateX(${_px}px)`,
                transition: "0.2s"
              }}
              id={"dotsRootV2"}
              className={classNames({
                [classes.dotsRootV2]: true,
                ["hidden"]: dotsVisible === "never",
                [classes.dotsNoTransform]: children.length <= visibleDots,
                [classes.dotsBlack]: darkDotTheme,
                [classes.dotsNoBorder]: false
              })}
            >
              {dots}
            </ul>
          </div>
          <ButtonBase
            id="carousel-arrow-button"
            onClick={e => {
              e?.preventDefault();
              onChangeIndex?.(index + 1);
            }}
          >
            <CarouselArrowIcon />
          </ButtonBase>
        </div>
      );
    }
  }
);

export const defaultProps = {
  arrowsSize: DEFAULT_ARROWS_SIZE as ArrowsSize,
  arrowsVisible: DEFAULT_ARROWS_VISIBLE as ArrowsVisible,
  dotsVisible: DEFAULT_DOTS_VISIBLE as DotsVisible,
  autoplaySpeed: AUTOPLAY_SPEED,
  index: 0,
  visibleDots: VISIBLE_DOTS
};
RHCarousel.defaultProps = defaultProps;
export default memo(RHCarousel);

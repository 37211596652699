import React from "react";
import memoize from "utils/memoize";
import { TailwindTypography } from "@RHCommerceDev/component-tailwind-typography";
interface WidthProps {
  name: string;
  className?: string;
}

const Width: React.FC<WidthProps> = ({ name, className }) => {
  return (
    <TailwindTypography
      className={`text-[#201F1F] text-[13px] font-thin font-primary-rhthin leading-[150%] tracking-[0.52px] uppercase ${className}`}
    >
      {name}
    </TailwindTypography>
  );
};

export default memoize(Width);

import React from "react";
import { TailwindTypography } from "@RHCommerceDev/component-tailwind-typography";
import memoize from "utils/memoize";

interface ProductProps {
  name: string;
  className?: string;
}

const Product: React.FC<ProductProps> = ({ name, className }) => {
  return (
    <TailwindTypography
      className={`text-rh-darker text-[40px] text-wrap font-primary-ultra-thin leading-[150%] tracking-[-2.8px] uppercase max-w-[700px] ${className}`}
      noWrap
    >
      {name}
    </TailwindTypography>
  );
};

export default memoize(Product);

import React from "react";
import { TailwindTypography } from "@RHCommerceDev/component-tailwind-typography";
import memoize from "utils/memoize";

interface CategoryProps {
  name: string;
  className?: string;
}

const Category: React.FC<CategoryProps> = ({ name, className }) => {
  return (
    <TailwindTypography
      className={`text-rh-darker text-[13px] font-thin font-primary-rhthin leading-[150%] tracking-[0.52px] uppercase ${className}`}
    >
      {name}
    </TailwindTypography>
  );
};

export default memoize(Category);

import React, { useMemo } from "react";
import Category from "./Category";
import Width from "./Width";
import Product from "./Product";
import Price from "./Price";
import memoize from "utils/memoize";

interface ComponentData {
  name: string;
  sortOrder: number;
}

interface DataProps {
  category: ComponentData;
  width: ComponentData;
  product: ComponentData;
  price: ComponentData;
}

interface PDPProductDetailsProps {
  data: DataProps;
  priceProps: any;
  className?: string;
}

const PDPProductDetails: React.FC<PDPProductDetailsProps> = ({
  data,
  priceProps,
  className = ""
}) => {
  const components = useMemo(() => {
    return {
      category: <Category name={data?.category?.name} />,
      width: <Width name={data?.width?.name} />,
      product: <Product name={data?.product?.name} />,
      price: <Price {...priceProps} />
    };
  }, [data, priceProps]);

  const sortedComponents = Object.keys(data)
    ?.map(key => ({ key, sortOrder: data[key as keyof DataProps].sortOrder }))
    ?.sort((a, b) => a.sortOrder - b.sortOrder);

  return (
    <div
      id={"component-pdp-product-details"}
      className={`flex items-baseline gap-1.5 ${className}`}
    >
      {sortedComponents.map(component => (
        <div key={component?.key}>{components[component?.key]}</div>
      ))}
    </div>
  );
};

export default memoize(PDPProductDetails);
